import {Component, Input} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ColumnComponent} from "./column/column.component";
import {Row} from "./types/row";
import {Column} from "./column/types/column";
import {environment} from "../../../../environments/environment";

@Component({
  standalone: true,
  selector: 'app-layout-row',
  imports: [CommonModule, ColumnComponent],
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.less']
})
export class RowComponent {

  @Input() layout!: Row;

  public getContainerClasses(): string[] {
    let classes = [
      'container',
      `container-${this.layout.data.size}`,
    ];

    if (this.layout.data.style) {
      classes.push(`layout-style-${this.layout.data.style}`)
    }

    return classes;
  }

  public getRowStyle(): object {
    let styles: any = {
      gap: this.layout.data.columnGap + 'px',
    };

    if (this.layout.data.backgroundImage) {
      let thumbnailSize = this.layout.data.backgroundImageCropping ?? 'full';

      if (!thumbnailSize || thumbnailSize === 'full') {
        styles.backgroundImage = `url(${environment.cdn.url}/cdn/${this.layout.data.backgroundImage})`;
      } else {
        styles.backgroundImage = `url(${environment.cdn.url}/cdn/thumbnails/${thumbnailSize}/${this.layout.data.backgroundImage})`
      }
    }

    if (this.layout.data.padding) {
      if (this.layout.data.padding.top) {
        styles.paddingTop = this.layout.data.padding.top;
      }
      if (this.layout.data.padding.bottom) {
        styles.paddingBottom = this.layout.data.padding.bottom;
      }
      if (this.layout.data.padding.left) {
        styles.paddingLeft = this.layout.data.padding.left;
      }
      if (this.layout.data.padding.right) {
        styles.paddingRight = this.layout.data.padding.right;
      }
    }

    if (this.layout.data.margin) {
      if (this.layout.data.margin.top) {
        styles.marginTop = this.layout.data.margin.top;
      }
      if (this.layout.data.margin.bottom) {
        styles.marginBottom = this.layout.data.margin.bottom;
      }
      if (this.layout.data.margin.left) {
        styles.marginLeft = this.layout.data.margin.left;
      }
      if (this.layout.data.margin.right) {
        styles.marginRight = this.layout.data.margin.right;
      }
    }

    if (this.layout.data.backgroundPosition) {
      if (typeof this.layout.data.backgroundPosition === 'string') {
        styles.backgroundPosition = this.layout.data.backgroundPosition;
      } else {
        if (this.layout.data.backgroundPosition?.x) {
          styles.backgroundPositionX = this.layout.data.backgroundPosition.x;
        }
        if (this.layout.data.backgroundPosition?.y) {
          styles.backgroundPositionY = this.layout.data.backgroundPosition.y;
        }
      }
    }

    if (this.layout.data.backgroundSize) {
      styles.backgroundSize = this.layout.data.backgroundSize;
    }

    if (this.layout.data.backgroundRepeat) {
      styles.backgroundRepeat = this.layout.data.backgroundRepeat;
    }

    if (this.layout.data.alignItems) {
      styles.alignItems = this.layout.data.alignItems;
    }

    return styles;
  }

  public getSectionStyle(column: Column): object {
    let styles: any = {};

    if (column.data.size) {
      styles.gridColumn = `span ${column.data.size}`;
    }

    return styles;
  }
}
