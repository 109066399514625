import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Tabs} from "./types/tabs";
import {AccordionModule} from "primeng/accordion";
import {ColumnComponent} from "../row/column/column.component";
import {TabComponent} from "./tab/tab.component";
import {MatTabsModule} from "@angular/material/tabs";
import {AccordionSectionComponent} from "../accordion/accordion-section/accordion-section.component";

@Component({
  selector: 'app-layout-tabs',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, AccordionModule, ColumnComponent, TabComponent, MatTabsModule, AccordionSectionComponent],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.less']
})
export class TabsComponent {
  @Input() layout!: Tabs;

  public getActiveIndex(): number[] | number {
    return this.layout.data.allowMultiple ? [0] : 0;
  }
}
